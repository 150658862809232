const frontPage = () => {

    let loaderTl = gsap.timeline();
    loaderTl.to('.loader__top', {
        y: '-100%',
        z: 0,
    }, 0)
    loaderTl.to('.loader__right', {
        x: '100%',
        z: 0,
    }, 0)
    loaderTl.to('.loader__bottom', {
        y: '100%',
        z: 0,
    }, 0)
    loaderTl.to('.loader__left', {
        x: '-100%',
        z: 0,
    }, 0)

    if (!loaderTl.isActive()) {
        loaderTl.to('.loader', {
            display: 'none'
        })
    }




    // Hero
    let hero = document.querySelector('.hero')
    if (hero) {
        const heroTl = gsap.timeline();
        heroTl
            .from('.hero__background', {
                opacity: 0,
                duration: 1.5
            })
            .from('.hero__content', {
                transform: "rotateY(20deg) rotateZ(-4deg)",
                opacity: 0,
                duration: 1
            })
    }






    // Services bottom
    let serviceBottom = document.querySelector('.service__bottom')
    if (serviceBottom) {
        const serviceBottomTl = gsap.timeline({
            scrollTrigger: {
                trigger: serviceBottom,
                start: '0 70%'
            }
        })
    
        serviceBottomTl
            .from('.service__bottom--icon div', {
                y: 50,
                opacity: 0.5,
                duration: 0.5,
                stagger: .2
            })
            .from('.service__bottom--title div', {
                y: 25,
                duration: .75,
                ease: 'power1.out',
                stagger: .2
            }, '<')
            .from('.service__bottom--content', {
                y: 25,
                opacity: 0,
                duration: 1,
                ease: 'power1.out',
                stagger: .2
            }, '<');
    }









    // SEO
    let seo = document.querySelector('.seo')
    if (seo) {
        const seoTl = gsap.timeline({
            scrollTrigger: {
                trigger: seo,
                start: '0 70%'
            }
        })
    
        seoTl
            .to('.seo__city--mask', {
                ease: 'power3.out',
                width: '100%',
                duration: 1,
                stagger: .1
            })
            .to('.seo__city--name', {
                opacity: 1
            })
            .to('.seo__city--mask', {
                ease: 'power2.in',
                top: '100%',
                duration: .5,
                stagger: .1
            })
    }

}

export default frontPage;